import { createI18n } from "vue-i18n";

import ku from "@/languages/ku";
import ar from "@/languages/ar";
import en from "@/languages/en";

const i18n = createI18n({
  locale: localStorage.getItem("lang") ?? "en",
  messages: {
    en: en,
    ku: ku,
    ar: ar,
  },
});

export default i18n;
