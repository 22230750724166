import { createApp } from "vue";
import App from "./App.vue";

import router from "./router";
import store from "./store.js";

import axios from "axios";
import VueAxios from "vue-axios";

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

import ToastPlugin from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';



import withUUID from "vue-uuid";

import i18n from "./services/i18n.service";

import ApiService from "@/services/api.service";

ApiService.setHeader();

const app = createApp(App);

app.use(router);
app.use(store);
app.use(VueAxios, axios);
app.use(VueSweetalert2);
app.use(withUUID);
app.use(i18n);
app.use(ToastPlugin);

app.mount("#app");
