import axios from "axios";

const ApiService = {
  //TODO: Add the base URL, for now we are using the mock API
  baseUrl: "https://gamefluence.net:9572/api",
  korekBaseUrl: "https://korek-he.trendy-technologies.com",

  setHeader() {
    axios.defaults.headers.common["lang"] =
      localStorage.getItem("gamefluence_lang") ?? "en";
  },

  async generateScript(phone, uuid, timestamp) {
    delete axios.defaults.headers.common["lang"];
    try {
  
      const response = await axios.get(
        this.korekBaseUrl +
          "/dcbprotect.php?action=script&ti=" +
          uuid +
          "&ts=" +
          timestamp +
          "&te=%23cta_button&servicename=gamefluenec&merchantname=Tornet"
      );

      return response;
    } catch (e) {
      return e.response;
    }
  },

  async login(phoneNumber,ti) {
    axios.defaults.headers.common["lang"] =
      localStorage.getItem("gamefluence_lang") ?? "en";
    try {
      const response = await axios.post(this.baseUrl + "/auth/login", {
        phoneNumber: phoneNumber,
        ti:ti
      });
      return response;
    } catch (e) {
      return e.response;
    }
  },

  async verify(phoneNumber, otp, ts, ti) {
    try {
      const response = await axios.post(this.baseUrl + "/auth/verify", {
        phoneNumber: phoneNumber,
        pincode: otp,
        ts: ts,
        ti: ti,
      });
      return response;
    } catch (e) {
      return e.response;
    }
  },

  async checkUserStatus(phoneNumber) {
    try {
      const response = await axios.post(this.baseUrl + "/auth/checkUserStatus", {
        phoneNumber: phoneNumber
      });
      return response;
    } catch (e) {
      return e.response;
    }
  },
};

export default ApiService;
