const en = {
  enterYourNumner: "Phone Number",
  sendSms: "Send Sms",
  otpMsg: "Enter Your Otp",
  subscribe:"Subscribe",
  invalidPhone:"This Phone number invalid",
  verificationCode:"Verification code",
  helloMsg:"Hello!Welcome",
  verify:"Verify",
  msg:"Welcome to Game-N-Go! You will be charged 500 IQD/day. To unsubscribe, send 0 to 12345."
};

export default en;
