const ar = {
  enterYourNumner: "أدخل رقمك",
  sendSms:"إرسال رسائل نصية قصيرة",
  otpMsg: "أدخل كلمة المرور",
  subscribe:"اشترك",
  invalidPhone:"رقم الهاتف هذا غير صالح",
  verificationCode:"رمز التحقق",
  helloMsg:"مرحبًا! أهلًا وسهلًا",
  verify:"تثبت",
  msg:"اهلا بك في Game-N-Go  تكلفة الاشتراك 500 د.ع/يوم  لإلغاء الاشتراك ارسل 0 الى 12345"

};

export default ar;