<template>
  <section id="login">

    <loading v-model:active="isLoading" color="#e03339" is-full-page="true" />

    <div id="logo">
      <img src="../assets/logo.png" alt="">
    </div>

    <!-- <div id="info">
      <img src="../assets/lock.png" alt="">
      <p>{{ $t("helloMsg") }}</p>
    </div> -->

    <div class="tel">
      <label for="phone" id="phonelabel">{{ $t("verificationCode") }}</label>

      <div id="otp">
        <input v-model="pin1" type="text" v-on:keyup="$event.target.nextElementSibling.focus()" maxlength="1" />
        <input v-model="pin2" type="text" v-on:keyup="$event.target.nextElementSibling.focus()" maxlength="1" />
        <input v-model="pin3" type="text" v-on:keyup="$event.target.nextElementSibling.focus()" maxlength="1" />
        <input v-model="pin4" type="text" maxlength="1" />
      </div>

      <button class="sendBtn" :disabled="disabled" id="cta_button" @click="verify()">{{ $t("verify") }}</button>
    </div>

  </section>
</template>




<script>

import { mask } from "vue-the-mask";

import ApiService from "@/services/api.service";
import notify from "../mixins/notifyMessage.vue";
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
export default {
  name: "verifyPage",
  directives: { mask },
  mixins: [notify],
  components: {
    Loading
  },
  data() {
    return {
      phoneNumber: "",
      pin1: "",
      pin2: "",
      pin3: "",
      pin4: "",
      checkUserStatusInterval: "",
      isLoading: false,
      disabled: false,
    }
  },
  methods: {
    async verify() {
      this.disabled = true;
      this.isLoading = true;
      let otp = this.pin1 + this.pin2 + this.pin3 + this.pin4;
      let phoneNumber = this.$store.state.phoneNumber;
      let ts = this.$store.state.ts;
      let ti = this.$store.state.ti;

      let response = await ApiService.verify(phoneNumber, otp, ts, ti);

      this.isLoading = false;

      if (response && response.status == 200) {

        if (response.data["data"] != "") {
          window.location.href = "https://gamefluence.hub.gamepix.com/?token=" + response.data["data"];
        } else {
          this.errorMessage(response.data["msg"]);
          const self = this;
          if (response.status == 200) {
            alert();
            this.checkUserStatusInterval = setInterval(async function () {
              let response = await ApiService.checkUserStatus(phoneNumber);
              if (response && response.status == 200) {
                if (response.data['status'] == 2) {
                  clearInterval(self.checkUserStatusInterval);
                  window.location.href = "https://gamefluence.hub.gamepix.com/?token=" + response.data["data"];
                } else {
                  if (response.data['status'] == 3) {
                    clearInterval(self.checkUserStatusInterval);
                  }
                  self.errorMessage(response.data["msg"]);
                  this.disabled = false;
                }
              } else {
                clearInterval(self.checkUserStatusInterval);
                self.errorMessage(response.data["msg"]);
                this.disabled = false;
              }
            }, 5000);
          }

        }
      } else {
        clearInterval(self.checkUserStatusInterval);
        this.disabled = false;
        this.errorMessage(response.data["msg"]);
      }

    }
  }
}
</script>

<style scoped lang="scss">
#login {

  @media (max-width: 600px) {
    background-color: #121227;
  }

  @media (min-width: 601px) {
    background-color: #121227;
  }

  overflow: hidden;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-size: cover;

  #logo {
    margin-top: 5%;
    width: 400px;
    height: 300px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  #info {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: end !important;
    margin-bottom: 1.5rem;
    height: fit-content;

    @media(min-width:600px) {
      width: 500px;
    }

    @media(max-width:768px) {
      width: 300px;
    }

    img {
      width: 200px;
      height: 200px;

      @media(max-width:600px) {
        width: 80px;
        height: 80px;
      }

    }

    p {
      color: #fff;
      font-size: 40px;

      @media(max-width:600px) {
        font-size: 25px;
      }
    }
  }


  .tel {
    margin-top: 15%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 100%;

    @media(min-width:600px) {
      width: 500px;
    }

    @media(max-width:600px) {
      width: 300px;
    }

    label {
      width: 100%;
      color: white;
      justify-content: start;
      //  text-align: left;
      font-size: 24px;
      margin-bottom: 1.5rem;
    }


    #otp {
      display: flex;
      justify-content: space-around;
      width: 100%;
      margin-bottom: 2rem;

      input {
        height: 50px;
        width: 50px;
        text-align: center;
        background-color: #ddd;
        font-size: 30px;
        outline: none;
        border: none;
        border-radius: 8px;

        @media (max-width: 375px) {
          height: 60px;
          width: 60px;
        }
      }
    }


    .sendBtn {
      height: 50px;
      color: white;
      background-color: #3fcea5;
      border: none;
      border-radius: .5rem;
      font-size: 18px;
      cursor: pointer;
      font-weight: bold;
      width: 100%;
    }
  }
}
</style>