import login from "./components/login.vue";
import verify from "./components/verify.vue";

import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/:lang?",
    name: "login",
    component: login,
  },
  {
    path: "/verify",
    name: "verify",
    component: verify,
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
