<template>
  <div>
 
    <router-view></router-view>
    <notifications/>

  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  mounted() {},
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  margin: 0px;
  padding: 0px;
}
body {
  margin: 0px !important;
  padding: 0px !important;
}
</style>
