<script>
export default {
    methods: {
        successMessage() {

            this.$toast.open({
                message:  this.$t('success'),
                position: 'top-right',
                type: 'success',
            });
        },
        errorMessage(msg) {

            this.$toast.open({
                message: msg,
                position: 'top-right',
                type: 'error',
            });
        },
        confirm() {
            return new Promise((resolve) => {
                this.$swal.fire({
                    title: this.$t('confirmMsg'),
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    cancelButtonText: this.$t('cancel'),
                    confirmButtonText: this.$t('yes'),
                    width: '300px',
                }).then((result) => {
                    resolve(result.value);
                });
            });
        },
    }
}
</script>